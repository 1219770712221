import { Ionicons } from '@expo/vector-icons';
import * as WebBrowser from 'expo-web-browser';
import React, {useState, useEffect, useReducer} from 'react';
import { Text, View, TouchableOpacity } from 'react-native';
import {Picker} from '@react-native-community/picker';
import { RectButton, ScrollView } from 'react-native-gesture-handler';
import { styles } from '../constants/styles';
import AsyncStorage from '@react-native-async-storage/async-storage';
import localforage from 'localforage';

import AzureFunc from '../hooks/azureFunc';
const azf = new AzureFunc();

export default function User(props, { navigation, route }) {

  const [currentAdmissionCenter, setCurrentAdmissionCenter] = useState(props.user.selectedRegistration.admissionCenterName);
  const [currentWard, setCurrentWard] = useState(props.user.selectedRegistration.wardName);
  const [admissionCenterName, setAdmissionCenterName] = useState(props.user.registrations.length ? props.user.registrations[0].admissionCenterName : false);
  const [admissionCenterId, setAdmissionCenterId] = useState(props.user.registrations.length ? props.user.registrations[0].admissionCenterId : '');
  const [ward, setWard] = useState(props.user.registrations.length ? props.user.registrations[0].wards[0] : {});
  const [registrations, setRegistrations] = useState(props.user.registrations);
  const [wards, setWards] = useState(props.user.registrations.length ? props.user.registrations[0].wards : []);
  const [units, setUnits] = useState(props.user.units);


  function selectAdmission(adm){
    if(adm && adm != 0){
      setAdmissionCenterName(adm.admissionCenterName)
      setAdmissionCenterId(adm.admissionCenterId)
      setWards(adm.wards)
      setWard(adm.wards[0])
    }
  }

  function selectWard(wId){
    //console.log(wId)
    if (wId && wId != 0){
      let w = wards.filter(w=> w.id == wId)[0]
      setWard(w)
    }
  }

  async function updateAdmission(){
    //console.log(ward)
    const userInfo = {
      ...props.user,
      selectedRegistration: {admissionCenterId, admissionCenterName, wardId: ward.id, wardName: ward.name}
    }
    const response = await azf.updateUserInfo(userInfo)
    setCurrentAdmissionCenter(admissionCenterName)
    setCurrentWard(ward.name)
    props.auth.updateUser(response)
  }

  async function changeUnits() {
    // False/0 = Celsius, True/1 = Fahrenheit
    const userInfo = {
      ...props.user,
      units: !units
    }
    //console.log(props.user)
    const response = await azf.updateUserInfo(userInfo)
    setUnits(!units)
    props.auth.updateUser(response)
    //console.log(response)
  }

  async function signOut(){
    props.auth.signOut()
  }

  async function clearCache(){
    localforage.clear()
  }

  return (
    <View style={styles.container}>
      <ScrollView style={styles.container} contentContainerStyle={styles.contentContainer}>
      <View style={[styles.row, {justifyContent:'center'}]}>
        <View style={[styles.col, styles.card]}>
          <View style={[styles.cardHeaderContainer,{marginBottom:20}]}>
            <Text style={styles.cardHeaderValue}>User Info</Text>
          </View>
          <Text style={styles.cardHeaderLabel}>Name:</Text>
          <Text style={[styles.cardHeaderValue,{alignSelf:'center'}]}>{props.user.name.first} {props.user.name.last}</Text>
          <Text style={styles.cardHeaderLabel}>Email:</Text>
          <Text style={[styles.cardHeaderValue,{alignSelf:'center'}]}>{props.userEmail}</Text>
          <Text style={styles.cardHeaderLabel}>Created:</Text>
          <Text style={[styles.cardHeaderValue,{alignSelf:'center'}]}>{new Date(props.user.created).toLocaleString()}</Text>
          <Text style={styles.cardHeaderLabel}>Last login date:</Text>
          <Text style={[styles.cardHeaderValue,{alignSelf:'center'}]}>{new Date(props.user.stats.lastLoginTime).toLocaleString()}</Text>
          <Text style={styles.cardHeaderLabel}>Last login ip:</Text>
          <Text style={[styles.cardHeaderValue,{alignSelf:'center'}]}>{props.user.stats.lastLoginIP}</Text>
          <Text style={styles.cardHeaderLabel}>Last password reset:</Text>
          <Text style={[styles.cardHeaderValue,{alignSelf:'center'}]}>{props.user.stats.lastPasswordReset ? new Date(props.user.stats.lastPasswordReset).toLocaleString() : 'n/a'}</Text>
          <Text style={styles.cardHeaderLabel}>Timezone:</Text>
          <Text style={[styles.cardHeaderValue,{alignSelf:'center'}]}>{props.user.tz ? props.user.tz.toString() : 'none'}</Text>

          <View style={[styles.cardHeaderContainer,{marginTop:20, marginBottom:20}]}>
            <Text style={styles.cardHeaderValue}>Options</Text>
          </View>
          <Text style={styles.cardHeaderLabel}>Units:</Text>
          <TouchableOpacity style={[styles.formBtn,{backgroundColor:'white'}]} onPress={() => changeUnits()}>
              <Text style={styles.formBtnText}>{units ? 'Imperial' : 'Metric'}</Text>
          </TouchableOpacity>
        </View>
        {admissionCenterName &&
          <View style={[styles.col, styles.card]}>
            <View style={[styles.cardHeaderContainer,{marginBottom:20}]}>
              <Text style={styles.cardHeaderValue}>Current Admission</Text>
            </View>
            <Text style={styles.cardHeaderLabel}>Admission Center:</Text>
            <Text style={[styles.cardHeaderValue,{alignSelf:'center'}]}>{currentAdmissionCenter}</Text>
            <Text style={styles.cardHeaderLabel}>Ward:</Text>
            <Text style={[styles.cardHeaderValue,{alignSelf:'center'}]}>{currentWard}</Text>

            <View style={[styles.cardHeaderContainer,{marginTop:20, marginBottom:20}]}>
              <Text style={styles.cardHeaderValue}>Change Admission</Text>
            </View>
            <Text style={styles.cardHeaderLabel}>Admission Center</Text>
            <Picker selectedValue={admissionCenterId}  style={styles.picker}
              onValueChange={(itemValue, itemIndex) => selectAdmission(registrations[itemIndex-1])} >
                <Picker.Item key={0} label={'Select admission...'} value={0} enabled={false}/>
              {
                registrations.map(
                  reg => <Picker.Item key={reg.admissionCenterId} label={reg.admissionCenterName}
                  value={reg.admissionCenterId} />)
              }
            </Picker>
            <Text style={styles.cardHeaderLabel}>Ward</Text>
            <Picker selectedValue={ward.id}  style={styles.picker}
              onValueChange={(itemValue, itemIndex) => selectWard(itemValue)}>
                <Picker.Item key={0} label={'Select ward...'} value={0} enabled={false}/>
              {
                wards.map(
                  (w) => <Picker.Item key={w.id} label={w.name} value={w.id} />)
              }
            </Picker>

            <TouchableOpacity style={[styles.formBtn,{backgroundColor:'white'}]} onPress={() => updateAdmission()}>
                <Text style={styles.formBtnText}>Update</Text>
            </TouchableOpacity>
          </View>
        }

      </View>
        <TouchableOpacity style={styles.formBtn} onPress={() => clearCache()}>
            <Text style={styles.formBtnText}>Clear All Data</Text>
        </TouchableOpacity>
        <TouchableOpacity style={styles.formBtn} onPress={() => signOut()}>
            <Text style={styles.formBtnText}>Logout</Text>
        </TouchableOpacity>
    </ScrollView>
    </View>
  );
}
